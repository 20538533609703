@import '../../assets/scss/utils.scss';

.not-found-container {
  margin-top: $navbar-height;

  @include media-breakpoint-up(lg) {
    margin-top: $navbar-height-lg;
  }

  .full-width {
    width: 100%;
  }

  .row-not-found {
    display: flex;
    align-items: center;
    min-height: calc(100vh - #{$navbar-height});
    padding-top: rem(16px);
    padding-bottom: rem(54px);

    @include media-breakpoint-up(lg) {
      padding-top: rem(64px);
      min-height: calc(100vh - #{$navbar-height-lg});
    }

    @include media-breakpoint-up(xl) {
      padding-top: rem(64px);
    }
  }

  .label-not-found,
  .title-extra-small {
    margin-bottom: rem(24px);
  }

  .title-extra-small {
    font-size: rem(38px);
    font-family: 'ClashDisplay';
    font-weight: 600;
    line-height: rem(46px);
    
    @include title-media-query();
  }

  .actions-container {
    display: flex;
    justify-content: center;
    margin-top: rem(40px);

    @include media-breakpoint-up(lg) {
      margin-top: rem(80px);
    }
  }

  .container-clothest {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: rem(56px);

    @include media-breakpoint-up(lg) {
      margin-bottom: rem(80px);
    }

  }

  .clothest-img {
    width: rem(92px);
    flex: 0 0 auto;

    @include media-breakpoint-up(md) {
      width: rem(170px);
    }
  }
}

