@font-face {
  font-family: 'HKGrotesk';
  src: url('../fonts/HK-Grotesk/HKGrotesk-Bold.eot');
  src: url('../fonts/HK-Grotesk/HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/HK-Grotesk/HKGrotesk-Bold.svg#HKGrotesk-Bold') format('svg'),
       url('../fonts/HK-Grotesk/HKGrotesk-Bold.ttf') format('truetype'),
       url('../fonts/HK-Grotesk/HKGrotesk-Bold.woff') format('woff'),
       url('../fonts/HK-Grotesk/HKGrotesk-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('../fonts/HK-Grotesk/HKGrotesk-Regular.eot');
  src: url('../fonts/HK-Grotesk/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/HK-Grotesk/HKGrotesk-Regular.svg#HKGrotesk-Regular') format('svg'),
       url('../fonts/HK-Grotesk/HKGrotesk-Regular.ttf') format('truetype'),
       url('../fonts/HK-Grotesk/HKGrotesk-Regular.woff') format('woff'),
       url('../fonts/HK-Grotesk/HKGrotesk-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay';
  src: url('../fonts/Clash-Display/ClashDisplay-Semibold.eot');
  src: url('../fonts/Clash-Display/ClashDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Clash-Display/ClashDisplay-Semibold.woff2') format('woff2'),
       url('../fonts/Clash-Display/ClashDisplay-Semibold.woff') format('woff'),
       url('../fonts/Clash-Display/ClashDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay';
  src: url('../fonts/Clash-Display/ClashDisplay-Bold.eot');
  src: url('../fonts/Clash-Display/ClashDisplay-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Clash-Display/ClashDisplay-Bold.woff2') format('woff2'),
       url('../fonts/Clash-Display/ClashDisplay-Bold.woff') format('woff'),
       url('../fonts/Clash-Display/ClashDisplay-Bold.ttf') format('truetype');
       font-weight: 700;
       font-style: normal;
}