@import '../../assets/scss/utils.scss';

$spacers: (
  xs: 10px,
  md: 12px,
);

$colums: 3;

@mixin get-splacer-size($spacer) {
  width: calc((100% - (#{rem($spacer)} * (#{$colums} - 1))) / #{$colums});
  margin-right: rem($spacer);
  margin-bottom: rem($spacer);

  &:nth-child(#{$colums}n) {
    margin-right: 0;
  }
}

.memory-mission {

  .btn-position {
    position: relative;

    .link-wrapper {
      @include media-breakpoint-up(xl) { 
        position: absolute;
        top: 0;
      }
    }
  }
  
  .title,
  .description,
  .card {
    opacity: 0;
  }
  
  /* .custom-container {
    display: flex;
    flex-direction: column;
  
    @include media-breakpoint-up(xl) { 
      flex-direction: row;
    }
  } */
  
  .content {
    justify-content: center;
  
    @include media-breakpoint-up(lg) { 
      text-align: center;
    }
  }
  
  .description {
    margin-top: rem(16px);
    margin-bottom: rem(24px);
    flex: auto;
  
    @include media-breakpoint-up(md) { 
      margin-bottom: rem(72px);
    }
  
    @include media-breakpoint-up(lg) { 
      margin-top: rem(24px);
      margin-bottom: 0;
    }
  
    @include media-breakpoint-up(xl) { 
      margin-top: 0;
    }
  }
  
  .grid {
    display: flex;
    flex-wrap: wrap;
    // width: 100%;
    // max-width: calc(100vh / 2.5);
    // margin: 0 auto;
  
    // @media (min-width: 376px) { 
    //   max-width: rem(340px);
    // }
  
    // @include media-breakpoint-up(md) { 
    //   max-width: rem(400px);
    // }
  
    @include media-breakpoint-up(lg) { 
      margin-bottom: rem(56px);
      order: -1;
    }
  
    @include media-breakpoint-up(xl) { 
      margin-bottom: rem(40px);
    }
  }
  
  .card {
    display: inline-block;
    cursor: pointer;
    position: relative;
  
    @include get-splacer-size(map-get($spacers, xs));
  
    @include media-breakpoint-up(md) { 
      @include get-splacer-size(map-get($spacers, md));
    }
  
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  
    &.flipped, &.has-match {
      & .image.front {
        transform: rotateY(180deg);
      }
  
      & .image.back {
        transform: rotateY(360deg);
      }
    }
  }
  
  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backface-visibility: hidden;
    transition: transform 0.3s ease-in-out;
    border-radius: rem(16px);
    background-color: map-get($flat, white);
    padding: rem(16px);
  
    @media (min-width: 376px) { 
      padding: rem(24px);
    }
    
    @include media-breakpoint-up(md) { 
      padding: rem(34px);
    }
  
    &.front {
      z-index: 1;
    }
  
    &.back {
      transform: rotateY(180deg);
    }
  }
  
  .no-event,
  .card.flipped, 
  .card.has-match {
    pointer-events: none;
  }
}  

