@import './../../assets/scss/utils.scss';

.btn-container {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: rem(150px);
  padding: rem(20px) rem(24px);
  color: map-get($flat, dark-purple);
  cursor: pointer;

  //SVG
  .cover-animated {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  //TEXT
  .text-container {
    overflow: hidden;
    padding: rem(6px); //show focus
    
    .text-wrapper {
      margin: 0;
      padding: 0;
      color: currentColor;
      display: flex;
      align-items: center;

      .icon {
        margin-left: rem(8px);
        flex: 0 0 auto;
      }
      
      &.with-keyboard:focus:after {
        border: rem(2px) solid map-get($flat, light-blue);
        border-radius: rem(12px);
        content: '';
        position: absolute;
        top: rem(-6px);
        right: rem(-6px);
        bottom: rem(-6px);
        left: rem(-6px);
      }
    }
  }
}
