@import '../../../../assets/scss/utils.scss';

.custom-container {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) { 
    justify-content: center;
  }
}

.illustrations-container {
  @include media-breakpoint-up(md) {
    margin-bottom: rem(64px);
  }
}

.header,
.description {
  margin-bottom: rem(24px);
}

.header,
.content {
  @include media-breakpoint-up(lg) { 
    max-width: rem(859px);  
    width: 100%;
    margin: 0 auto;
  }

  @include media-breakpoint-up(xl) { 
    max-width: rem(908px);
  }

}

.header {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) { 
    flex-direction: row-reverse;
    margin-bottom: rem(48px);
  }
}

.title {
  flex: 1;
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;
  margin-bottom: rem(16px);

  & .description,
  & .list {
    flex: 0 0 100%;
  }


  @include media-breakpoint-up(md) { 
    flex-direction: row;
    justify-content: space-between;

    & .description,
    & .list {
      flex: 0 0 48%;
    }
  }

  @include media-breakpoint-up(lg) { 
    flex-direction: row;
  }
}

.list-container {
  margin: 0;
}

.list-title,
.mission-counter {
  margin-bottom: rem(16px);
}

.mission-counter {
  display: block;

  &.hidden {
    display: none;
  }
}

.btn-container {
  text-align: center;
  flex: 0 0 100%;
  justify-self: flex-end;
}

.mission-counter,
.illustrations,
.title,
.content,
.btn-container {
  opacity: 0
}