@import '../../assets/scss/utils.scss';

.subscription-page-container {
  .section-parallax {
    height: 60vh;
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: -1;

    @include media-breakpoint-up(lg) {
      height: 75vh;
    }
  }

  .section-content {
    background: #fff;
    position: relative;
    z-index: 1;
  }

  .custom-container {
    padding-top: rem(32px);
    padding-bottom: rem(80px);
    position: relative;

  }

  .cloud-background {
    width: 100%;
    position: absolute;
    transform: translateY(-99%); // TODO: per risolvere il glitch di 1px 
  }

  .title,
  .description-row {
    text-align: center;
  }

  .description-row {
    margin-top: rem(16px);
    margin-bottom: rem(24px);
    justify-content: center;

    @include media-breakpoint-up(md) {
      margin-top: rem(32px);
    }
  }

  .sub-description {
    margin-bottom: rem(32px);

    @include media-breakpoint-up(lg) {
      margin-bottom: rem(40px);
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: rem(32px);
    }
  }

  .form {
    justify-content: center;
  }

  .checkbox {
    margin-bottom: rem(16px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .checkbox-paragraph {
    margin: 0;
  }

  .form-btn-container {
    text-align: center;
    margin: rem(32px) 0;

    @include media-breakpoint-up(lg) {
      margin: rem(40px) 0;
    }

    @include media-breakpoint-up(xl) {
      margin: rem(32px) 0;
    }
  }

  .about-us-section {
    text-align: center;
  }

  .link {
    color: map-get($flat, dark-blue);

    &:hover {
      color: map-get($flat, dark-purple);
    }
  }
}
