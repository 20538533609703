@import '../../assets/scss/utils.scss';

.scroller-button {
  border: none;
  background-color: transparent;
  color: map-get($flat, dark-purple);
  padding: rem(16px);
  margin: rem(6px);
  position: relative;
  user-select: none;

  .scroller-svg {
    position: relative;
    overflow: visible;
  }

  &.with-keyboard:focus {
    &:after {
      border: rem(2px) solid map-get($flat, light-blue);
      border-radius: 50%;
      content: '';
      position: absolute;
      top: rem(-6px);
      right: rem(-6px);
      bottom: rem(-6px);
      left: rem(-6px);
    }
  }

  .text-path {
    font-size: rem(28px);
  }
}
