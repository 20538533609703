@import '../../assets/scss/utils.scss';

.thank-you-page-container {
  padding-top: rem(54px);
  padding-bottom: rem(54px);
  margin-top: $navbar-height;
  min-height: calc(100vh - #{$navbar-height});
  display: flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    margin-top: $navbar-height-lg;
    min-height: calc(100vh - #{$navbar-height-lg});
  }

  @include media-breakpoint-up(xl) {
    padding-top: rem(16px);
  }

  .thank-paragraph {
    margin-top: rem(24px);

    @include media-breakpoint-up(xl) {
      margin-top: rem(32px);
    }
  }

  .thank-you-container-info {
    margin-bottom: rem(40px);
  
    @include media-breakpoint-up(md) {
      margin-bottom: rem(56px);
    }
  
    @include media-breakpoint-up(lg) {
      margin-bottom: rem(80px);
    }
  
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(40px);
    }
  }

  .arrow-icons {
    display: flex;

    .link-wrapper:last-child {
      display: block;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  .choose-title {
    margin-bottom: rem(16px);
  }

  .choose-paragraph {
    width: 100%;
    margin-bottom: rem(24px);

    @include media-breakpoint-up(md) {
      margin-bottom: rem(40px);
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: rem(24px);
    }
  }

  .slider-container {
    margin: 0 rem(-12px);
    display: flex;
    flex-direction: column;

    .image-slide {
      width: 100%;
      max-width: rem(272px);
      padding: 0 rem(6px);
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        max-width: rem(216px);
      }
    }

    .image-card {
      border-radius: rem(27px);
      width: 100%;
    }
  }

  .slider-info-container {
    order: 0;
  }

  .swiper-slider-wrapper {
    order: 1;
  }

  .navigation-buttons {
    margin-bottom: rem(16px);
    margin-top: rem(24px);
    order: 2;
    display: flex;
    align-items: center;
    padding: 0 rem(12px);
    padding-right: rem(16px);
  
    @include media-breakpoint-up(md) { 
      padding-right: rem(32px);
    }

    .swiper-button-disabled {
      opacity: 0.6;

      &:hover {
        background-color: transparent;
        color: map-get($flat, dark-purple);
      }
    }

    & .icon-btn:nth-child(1) {
      margin-right: rem(16px);
    }

    @include media-breakpoint-up(md) {
      order: 1;
      margin: 0;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  .play-again {
    text-align: center;
    
    @include media-breakpoint-up(md) {
      margin-top: rem(36px);
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .negative-margin-right {
    margin: 0 rem(-16px);
  
    @include media-breakpoint-up(md) {
      margin-left: 0;
      margin-right: rem(-32px);
    }
  }
}