@import '../../../../assets/scss/utils.scss';

.preloader-container {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: map-get($flat, dark-purple);
  background: #fff;
  overflow: hidden;

  .preloader-wrapper {
    z-index: 4;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .svg-wrapper {
    padding: rem(24px);

    .svg-container {
      overflow: visible;
      position: relative;
    }

    .text-svg {
      font-size: rem(32px);
      font-family: 'ClashDisplay';;

      tspan {
        font-size: rem(36px);
      }
    }

  }

  .description-container {
    width: calc(100% - 40px);
    text-align: center;
    margin: rem(48px) 0;
    color: map-get($flat, white);

    @include media-breakpoint-up(md) {
      width: 40%;
    }
  
    @include media-breakpoint-up(lg) {
      width: 33.333333%;
    }
  }

  .description {
    margin-bottom: rem(48px);

    .paragraph-description {
      margin-bottom: rem(24px);
    }
  }
}

.container-loader {
  position: fixed;
  background-color: map-get($flat, dark-blue);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  z-index: 3;
}
