@import '../../assets/scss/utils.scss';

.container-start-missions {
  padding-top: rem(16px);
  padding-bottom: rem(48px);
  margin-top: $navbar-height;
  min-height: calc(100vh - #{$navbar-height});
  display: flex;
  justify-content: center;
  
  .container-mission {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    padding-top: rem(54px);
  }

  @include media-breakpoint-up(lg) {
    padding-top: rem(64px);
    margin-top: $navbar-height-lg;
    min-height: calc(100vh - #{$navbar-height-lg});
  }

  @include media-breakpoint-up(xl) {
    padding-top: rem(64px);
  }

  .text-path {
    opacity: 0;
  }

  .container-door {
    height: rem(200px);
    width: rem(120px);
    margin-top: rem(32px);

    @media (min-width: 376px) { 
      height: rem(283px);
      width: rem(170px);  
    }
  

    @include media-breakpoint-up(md) {
      height: rem(450px);
      width: rem(270px);
      margin-top: rem(40px);
    }

    @include media-breakpoint-up(lg) {
      height: rem(495px);
      width: rem(298px);
    }

    @include media-breakpoint-up(xl) {
      height: rem(343px);
      width: rem(206px);
    }
  }

  .door-svg {
    overflow: visible;
  }

  .door-mask {
    border-top-left-radius: rem(130px);
    border-top-right-radius: rem(130px);
    overflow: hidden;
    height: 100%;
    transform: translateY(-100%);
  }

  .page-description {
    margin: rem(40px) 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: rem(32px);
    }

    @include media-breakpoint-up(xl) {
      margin-top: rem(24px);
    }
  }
}

