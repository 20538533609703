@import './../../assets/scss/utils.scss';


.share-dialog-container {

  .MuiDialog-scrollPaper {
    @include media-breakpoint-up(md) {
      align-items: center;
    }
  }

  .MuiDialog-paper {
    @include media-breakpoint-up(md) {
      max-width: rem(392px);
      border-radius: rem(16px);
    }
  }

  .MuiDialogActions-root {
    @include media-breakpoint-up(md) {
      padding-bottom: rem(40px);
    }
  }

  .close-btn {
    width: rem(32px);
    height: rem(32px);
    background-color: transparent;
    padding: 0;
    color: currentColor;

    .svg-icon {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: currentColor
    }

    &:hover {
      color: map-get($flat, dark-purple);
    }
  }

  .link-rounded-container {
  
    .svg-icon {
      fill: none;
      stroke: currentColor
    }
  }
  
  .shared-image {
    border-radius: rem(27px);
    width: 100%;
  }
}

