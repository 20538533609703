@import './../../assets/scss/utils.scss';

.disable-text-selection {
  user-select: none;
}

.round-button {
  border: transparent;
  background-color: transparent;
  position: relative;
  padding: rem(16px);
  margin: rem(6px);
  color: map-get($flat, dark-purple);

  .round-button-wrapper {
    position: relative;
    overflow: visible;
  
    .text-svg {
      font-size: rem(32px);
    }
  
    .circle-container {
      transition: transform 0.5s linear;
      transform: scaleX(-1) translate(-100%);
    }
  }

  &.with-keyboard:focus {
    &:after {
      border: rem(2px) solid map-get($flat, light-blue);
      border-radius: 50%;
      content: '';
      position: absolute;
      top: rem(-6px);
      right: rem(-6px);
      bottom: rem(-6px);
      left: rem(-6px);
    }
  }
}