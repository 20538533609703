@import '../../../assets/scss/utils.scss';

.illustrations-container {
  display: flex;
  justify-content: space-between;
  margin: 0 0 rem(8px) 0;
  width: 100%;

  @include media-breakpoint-up(lg) {
    margin: auto;
    width: auto;
  }

  & .image {
    width: 20%;
    object-fit: contain;

    @media (min-width: 376px) { 
      width: rem(98px);
    }

    @include media-breakpoint-up(md) {
      width: rem(180px);
    }

    @include media-breakpoint-up(lg) {
      $translateY: 35%;

      &:nth-child(1) {
        transform: translateY(-$translateY);
      }

      &:nth-child(2) {
        transform: translateY($translateY);
      }
    }

    &.hidden {
      opacity: 0;
    }

    &.thumbnail {
      width: rem(78px);
      margin: 0;
    }
  }
}
