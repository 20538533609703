@import './../../assets/scss/utils.scss';

.link-wrapper {
  overflow: hidden;
  display: inline-flex;

  .uppercase {
    text-transform: uppercase;
  }

  .link-container {
    display: inline-block;
    text-decoration: none;
    color: map-get($flat, dark-purple);
    padding: rem(12px);
    margin: rem(-12px);

    &.with-keyboard:focus {

      &:after {
        border: rem(2px) solid map-get($flat, light-blue);
        border-radius: rem(12px);
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .svg-icon {
      height: rem(22px);
      width: rem(22px);
      margin-right: rem(8px);
    }
  }
}
