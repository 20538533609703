@import '../../../../assets/scss/utils.scss';

.third-mission-end-container {
  &,
  .content {
    display: flex;
    flex-direction: column;
  }

  .content {
    flex: 1;
    justify-content: center;
    
    @include media-breakpoint-up(lg) {
      align-items: center;
    }
  }

  .description {
    margin-top: rem(32px);
    width: 100%;
    max-width: rem(300px);

    @include media-breakpoint-up(md) {
      max-width: rem(588px);
    }
    
    @include media-breakpoint-up(lg) {
      margin-top: rem(48px);
      max-width: rem(699px);
    }
  }

  .round-btn-container {
    flex: 1;
    text-align: center;
  }
}
