@import '../../assets/scss/utils.scss';

.icon-btn,
.icon-btn:focus:after {
  border-radius: rem(100px);
}

.icon-btn {
  border: rem(2px) solid map-get($flat, dark-purple);
  color: map-get($flat, dark-purple);
  height: rem(44px);
  padding: rem(8px);
  width: rem(44px);
  transition: all 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover,
  &.with-keyboard:focus {
    color: map-get($flat, white);
  }

  &:hover {
    background-color: map-get($flat, dark-purple);
  }

  &.with-keyboard:focus {
    border-color: map-get($flat, darker-purple);
    background-color: map-get($flat, darker-purple);

    &:after {
      border: rem(2px) solid map-get($flat, light-blue);
      content: '';
      padding: rem(4px);
      position: absolute;
      top: rem(-6px);
      right: rem(-6px);
      bottom: rem(-6px);
      left: rem(-6px);
    }
  }

  .svg-icon {
    height: rem(22px);
    width: rem(22px);
  }
}

