@import './../../assets/scss/utils.scss';
@import '~bootstrap/scss/_containers.scss';
@import '~bootstrap/scss/_nav.scss';
@import '~bootstrap/scss/_navbar.scss';
@import '~bootstrap/scss/_transitions.scss';

$tooltip-bg: map-get($flat, dark-purple);
$tooltip-arrow-color: map-get($flat, dark-purple);
$tooltip-padding-y: rem(10px);
$tooltip-padding-x: rem(10px);
// TODO: eliminare le media query in più

@import '~bootstrap/scss/_tooltip.scss';

@include media-breakpoint-up(lg) {
  .navbar > .container-fluid {
    display: block;
  }
}

.navbar-container {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: rem(16px) 0;

  @include media-breakpoint-up(lg) {
    padding: rem(28px) 0;
  }

  .navbar-brand-container {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      position: initial;
      top: unset;
      left: unset;
    }

    .navbar-brand {
      color: map-get($flat, dark-blue);
      padding: 0;
      margin: 0;
      transition: color 0.3s ease-in-out;
    }

    .navbar-toggler {
      z-index: 1;
      border: 0;
      background: transparent;
      width: rem(32px);
      height: rem(32px);
      padding: rem(8px) rem(4px);

      .icon-bar {
        display: block;
        width: 100%;
        height: 2px;
        background-color: map-get($flat, dark-blue);
        margin-top: rem(5px);
        transition: all 0.2s;

        &:first-child {
          margin-top: 0;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.menu-open {
    .navbar-brand {
      color: map-get($flat, white) !important;

      @include media-breakpoint-up(lg) {
        color: map-get($flat, dark-blue) !important;
      }
    }

    .navbar-collapse {
      transform: translateX(0);

      @include media-breakpoint-up(lg) {
        transform: none;
      }
    }

    .navbar-toggler {

      .icon-bar {
        background-color: map-get($flat, white);
      }
  
      .top-bar {
        transform: rotate(45deg);
        transform-origin: 5% 10%;
      }
  
      .middle-bar {
        opacity: 0;
      }
  
      .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
      }
    }
  }

  .navbar-collapse {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding-top: $navbar-height;
    padding-bottom: rem(24px);
    background-color: map-get($flat, dark-blue);
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);

    @include media-breakpoint-up(md) {
      align-items: center;
    }

    @include media-breakpoint-up(lg) {
      position: initial;
      top: unset;
      left: unset;
      bottom: unset;
      right: unset;
      padding: 0;
      background-color: transparent;
      transition: none;
      transform: none;
      display: block;
    }

    &.collapsing {
      height: 100%;
      transition: transform 0.3s ease-in-out;
    }

    .navbar-nav {
      @include media-breakpoint-up(lg) {
        position: fixed;
        top: 50%;
        left: rem(32px);
        transform: rotate(90deg) translateX(calc(-50% - #{$navbar-height-lg / 2}));
        transform-origin: 0 100%;
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
      }

      .nav-link-subtitle {
        margin-top: rem(6px);
        display: block;
        font-family: 'HKGrotesk';
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(19px);
      }

      .nav-item {
        margin-left: 0;
        margin-bottom: rem(24px);

        .nav-link {
          color: map-get($flat, white);
          padding: 0;
          display: inline-flex;
          align-items: center;

          &.active {
            color: map-get($flat, light-purple);

            .icon-arrow {
              display: inline-block;
              width: rem(24px);
              height: rem(24px);
              transform: rotate(180deg);
              margin-right: rem(14px);
            }
          }

          .icon-arrow {
            color: currentColor;
            display: none;
          }

          &.disabled {
            opacity: 0.6;
            pointer-events: none;

            .icon-lock {
              width: rem(24px);
              height: rem(24px);
              margin-left: rem(16px);
            }

            &.with-keyboard:focus:after {
              top: rem(-8px);
              right: rem(-8px);
              bottom: rem(-8px);
              left: rem(-8px);
            }
          }

          &.with-keyboard:focus{
            outline: none;
            &:after {
              border: rem(2px) solid map-get($flat, light-blue);
              border-radius: rem(12px);
              content: '';
              position: absolute;
              top: rem(-3px);
              right: rem(-8px);
              bottom: rem(-3px);
              left: rem(-8px);
            }
          }

          .underline-text {
            display: inline-block;
            vertical-align: middle;
          }

          .link-underlined {
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }

    @media only screen and (min-width: 375px) {
      .navbar-nav {

        .nav-link-subtitle {
          margin-top: rem(10px);
          font-size: rem(16px);
        }

        .nav-item {
          margin-bottom: rem(24px);
        }
      }
    }

    @include media-breakpoint-up(lg) {

      .navbar-nav {

        .nav-link-subtitle {
          display: none;
        }

        .nav-item {
          margin-left: rem(32px);
          margin-bottom: 0;

          &:first-child{
            margin-left: 0;
          }

          .nav-link {
            transform: rotate(180deg);
            color: map-get($flat, dark-blue);
            padding: 0;
            position: relative;
            font-size: rem(14px);
            margin-bottom: 0;

            &.active{
              color: map-get($flat, dark-purple);

              .link-underlined::before {
                transform-origin: 0% 50%;
                transform: scale3d(2, 1, 1);
              }

              .icon-arrow {
                display: none;
              }
            }

            
            &.disabled {
              color: map-get($flat, dark-blue);

              .icon-lock {
                width: rem(18px);
                height: rem(18px);
                margin-left: rem(8px);
              }
            }

            &.text-white {
              color: map-get($flat, white);
            }
          }
        }
      }
    }

    .btn-container {
      color: map-get($flat, white);

      @include media-breakpoint-up(lg) {
        color: map-get($flat, dark-purple);
        position: fixed;
        right: rem(32px);
        top: rem(8px);
      }
    }
  }

  .audio-btn-container {
    position: fixed;
    left: map-get($container-padding, left);
    bottom: map-get($container-padding, bottom);

    @include media-breakpoint-up(lg) {
      position: static;

      .audio-btn {
        transform: rotate(-90deg);
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .link-underlined-container {
    overflow: hidden;
    position: relative;
    display: inline-block;

    .link-underlined {
      line-height: 2;
      font-weight: 700;
  
      &::before,
      &::after {
        position: absolute;
        right: 0;
        left: 0;
        height: rem(2px);
        background-color: currentColor;
        transform-origin: 100% 50%;
        transition: transform 0.3s cubic-bezier(0.5, 0.5, 0.3, 1);
      }
  
      &::before {
        bottom: rem(5px);
        content: '';
        transform: scale3d(0, 1, 1);
      }
  
      &::after {
        left: 0;
        top: 0;
        content: attr(data-text);
        background: none;
        transform: translate3d(150%, 0, 0);
      }
  
      &:hover::before {
        transform-origin: 0% 50%;
        transform: scale3d(2, 1, 1);
      }
  
      &:hover::after {
        transform: translate3d(0, 0, 0);
      }
  
      .underline-text {
        transition: transform 0.3s cubic-bezier(0.5, 0.5, 0.3, 1);
      }
  
      &:hover .underline-text {
        transform: translate3d(-150%, 0, 0);
      }
    }
  }
}

.tooltip {
  display: none;
  font-size: rem(16px);
  font-family: 'HKGrotesk';
  font-weight: 700;
  line-height: rem(19px);

  @include media-breakpoint-up(lg) {
    display: initial;
  }

  .tooltip-inner {
    text-align: left;
  }
}
