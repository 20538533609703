@import '../../assets/scss/utils.scss';

.connect-dots-container {
  background: url('../../assets/images/connect-dots-background.jpg') no-repeat center top;
  background-size: cover;
  position: relative;
  padding-top: rem($navbar-height);
  min-height: 100vh;

  @media (min-width: 376px) { 
    background-position: center;
  }

  @include media-breakpoint-up(md) {
    padding-top: calc(#{rem($navbar-height)} + #{rem(24px)});
  }

  .back-btn-container {
    margin-bottom: rem(16px);
  }
  
  .dots-grid {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  
    @media (min-width: 427px) {
      width: rem(427px);
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.disable-pointer {
  pointer-events: none;
}

.dialog-custom {
  top: unset !important;
  
  .MuiDialog-scrollPaper {
    height: auto !important;
    align-items: flex-end !important;
  }
  
  .MuiDialog-paper {
    padding: rem(16px) !important;
    border-radius: rem(16px) !important;
  }
  
  .MuiDialogTitle-root {
    padding: 0 !important;
    display: flex !important;
    align-items: center;
  }
  
  .MuiDialogContent-root {
    margin-top: rem(16px) !important;
    padding: 0 !important;
  }  

  .social-card-cover {
    height: rem(24px);
    width: rem(24px);
  }
  
  .social-card-author-name {
    display: inline-block;
    margin-left: rem(8px);
    margin-bottom: 0;
  }

  .close-btn-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    & .close-btn {
      padding: 0;
    }

    & .close-icon {
      height: rem(30px);
      padding: rem(8px);
      width: rem(30px);  
    }
  }
}