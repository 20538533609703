@import './utils.scss';
@import '~bootstrap/scss/_reboot.scss';
@import '~bootstrap/scss/_grid.scss';

body {
  font-family: 'HKGrotesk', sans-serif;
  background-image: map-get($flat, light-grey);
  background-image: radial-gradient(at 88% 1%, hsla(196,80%,65%,1) 0, transparent 58%),  radial-gradient(at 34% 65%, hsla(13,80%,64%,1) 0, transparent 59%),  radial-gradient(at 76% 35%, hsla(339,100%,71%,1) 0, transparent 58%),  radial-gradient(at 25% 30%, hsla(50,98%,84%,1) 0, transparent 50%),  radial-gradient(at 49% 48%, hsla(24,77%,69%,1) 0, transparent 50%),  radial-gradient(at 0% 5%, hsla(355,85%,93%,1) 0, transparent 50%),  radial-gradient(at 57% 28%, hsla(288,88%,79%,1) 0, transparent 50%),  radial-gradient(at 13% 86%, hsla(343,49%,61%,1) 0, transparent 50%),  radial-gradient(at 89% 84%, hsla(279,50%,55%,1) 0, transparent 50%),  radial-gradient(at 60% 78%, hsla(335,82%,68%,1) 0, transparent 50%);
  background-color: rgba(240, 240, 240, 1);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  overflow-x: hidden;
  width: 100%;

  &.navbar-open {
    overflow: hidden;
  }

  @include media-breakpoint-up(xl) { 
    background-color: rgba(240, 240, 240, 0.9);
  }

  .container-fluid {
    padding-right: rem(16px);
    padding-left: rem(16px);
  
    @include media-breakpoint-up(md) { 
      padding-right: rem(32px);
      padding-left: rem(32px);
    }
  }
}


.btn {
  border: 0;
  background-color: transparent;
  outline: none;
}

.primary-container {
  min-height: 100vh;
  height: 100%;
  padding: rem(map-get($container-padding, top)) rem(map-get($container-padding, right)) rem(map-get($container-padding, bottom)) rem(map-get($container-padding, left));

  @include media-breakpoint-up(lg) { 
    padding: rem(map-get($container-lg-padding, top)) rem(map-get($container-lg-padding, right)) rem(map-get($container-lg-padding, bottom)) rem(map-get($container-lg-padding, left));
  }
}

.m-0 {
  margin: 0;
}