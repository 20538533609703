@import '../../assets/scss/utils.scss';

.custom-container {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1;

  @include media-breakpoint-up(lg) {
    flex-direction: column-reverse;
    text-align: center;
  }
}

.description {
  margin-top: rem(16px);
  margin-bottom: rem(24px);
  max-width: rem(230px);
  width: 100%;
  flex: 0;

  @include media-breakpoint-up(md) {
    max-width: 100%;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    margin-bottom: rem(32px);
    flex: 1;
  }
}

.grid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    flex: 1;
    align-items: center;
  }

  &.no-interaction {
    pointer-events: none;
  }
}

.grid {
  display: flex;
  position: relative;
  width: rem(300px);
  height: rem(300px);

  @include media-breakpoint-up(md) {
    width: rem(415px);
    height: rem(415px);
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/background-puzzle-solved.jpeg') no-repeat center center/cover;
}

.card {
  cursor: pointer;
  position: absolute;
  width: calc(100% / 3);
}

.image {
  width: 100%;
}

.helper {
  margin: rem(24px) rem(-12px) rem(-12px);
  text-transform: uppercase;
  display: inline-block;
  padding: rem(12px);

  @include media-breakpoint-up(md) {
    margin-top: rem(32px);
  }

  @include media-breakpoint-up(xxl) {
    margin-top: rem(24px);
  }
}
