//Title

@mixin title-media-query() {
  @include media-breakpoint-up(md) {
    font-size: rem(56px);
    line-height: rem(69px);
  }

  @include media-breakpoint-up(lg) {
    font-size: rem(64px);
    line-height: rem(79px);
  }
}

.title-extra {
  font-size: rem(48px);
  font-family: 'ClashDisplay';
  font-weight: 600;
  line-height: rem(59px);
  
  @include title-media-query();
}

.header-1 {
  font-size: rem(36px);
  font-family: 'ClashDisplay';
  font-weight: 600;
  line-height: rem(47px);
}

.page-title {
  @extend .header-1;
  @include title-media-query();
}

.header-2 {
  font-size: rem(24px);
  font-family: 'ClashDisplay';
  font-weight: 700;
  line-height: rem(30px);
}

.header-3 {
  font-size: rem(20px);
  font-family: 'ClashDisplay';
  font-weight: 700;
  line-height: rem(25px);
}
//Navigation
.menu-item {
  font-size: rem(36px);
  font-family: 'ClashDisplay';
  font-weight: 700;
  line-height: rem(45px);

  @include media-breakpoint-up(lg) {
    font-size: rem(14px);
    line-height: rem(17px);
  }
}
//Buttons
.text-button-large {
  font-size: rem(20px);
  font-family: 'ClashDisplay';
  font-weight: 700;
  line-height: rem(25px);
}

.text-button-small {
  font-size: rem(16px);
  font-family: 'ClashDisplay';
  font-weight: 700;
  line-height: rem(20px);
}

.text-button {
  font-size: rem(14px);
  font-family: 'ClashDisplay';
  font-weight: 700;
  text-transform: uppercase;
  line-height: rem(17px);
}

.paragraph,
.paragraph-extrasmall,
.paragraph-large {
  font-family: 'HKGrotesk';
}

.paragraph {
  font-size: rem(16px);
  line-height: rem(20px);
}

.paragraph-uppercase {
  @extend .paragraph;
  text-transform: uppercase;
}

.paragraph-bold {
  @extend .paragraph;
  font-weight: 700;
}

.paragraph-extrasmall {
  font-size: rem(12px);
  line-height: rem(14px);
}

.paragraph-extrasmall-bold {
  @extend .paragraph-extrasmall;
  font-family: 'ClashDisplay';
  font-weight: 700;
  line-height: rem(15px);
}

.paragraph-large {
  font-size: rem(24px);
  line-height: rem(29px);
}

.paragraph-large-bold {
  @extend .paragraph-large;
  font-weight: 700;
}

//Form
.form-label {
  @extend .paragraph-extrasmall-bold;
  font-family: 'HKGrotesk';
  line-height: rem(14px);
}
