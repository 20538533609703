$flat: (
  light-purple: #B2A3FC,
  dark-purple: #7B61FF,
  darker-purple: #442ACA,
  dark-blue: #192942,
  light-blue: #2E7CF6,
  white: #FFFFFF,
  light-grey: #F0F0F0,
  error: #FB3D3F,
);

$gradient: (
  blue: linear-gradient(180deg, rgba(25, 41, 66, 0) 0%, #192942 82.21%),
  blue-inverse: linear-gradient(180deg, #192942 -47.78%, rgba(25, 41, 66, 0) 84.61%),
);

:export {
  // FLAT
  flatLightPurple: map-get($flat, light-purple);
  flatDarkPurple: map-get($flat, dark-purple);
  flatDarkerPurple: map-get($flat, darker-purple);
  flatDarkBlue: map-get($flat, dark-blue);
  flatLightBlue: map-get($flat, light-blue);
  flatWhite: map-get($flat, white);
  flatLightGrey: map-get($flat, light-grey);
  flatError: map-get($flat, error);
  // GRADIENT
  gradientBlue: map-get($gradient, blue);
  gradientBlueInverse: map-get($gradient, blue-inverse);
}
