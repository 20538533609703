@import '../../../../assets/scss/utils.scss';

.intro-step-one-container {
  .content {
    height: rem(308px);
    width: rem(185px);
    position: relative;
  }

  .content-label {
    margin-top: rem(16px);

    @include media-breakpoint-up(md) {
      margin-top: rem(24px);
    }

    @include media-breakpoint-up(xl) {
      margin-top: rem(40px);
    }
  }

  .label,
  .description {
    color: #fff;
  }

  .description {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(25, 41, 66, 0) 0%, #192942 31.77%);
    padding: rem(16px);
    text-align: center;

    @include media-breakpoint-up(xl) {
      padding: rem(52px) rem(26px);
    }
  }

  .header-1-size-text {
    font-size: rem(44px);
  }

  .description,
  .description-paragraph,
  .round-button {
    opacity: 0;
    transform: translateY(100%);
  }
  

  .description-paragraph {
    max-width: rem(408px);
    width: 100%;
    margin: rem(16px) auto rem(32px) auto;

    @include media-breakpoint-up(md) {
      max-width: rem(520px);
    }
  }

  .door-svg {
    overflow: visible;

    &::after {
      content: attr(data-text);
      display: block;
      text-align: center;
      margin-top: rem(16px);
      font-family: 'HKGrotesk';
      font-weight: 400;
      line-height: rem(19px);
    }
  }

  .door-mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: rem(130px) rem(130px) 0 0;
    overflow: hidden;
    z-index: 1;
  }

  .cover-container {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .planets-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-path {
    opacity: 0;
  }

  .section {
    height: 100%;
    width: 100%;
    position: relative;
    scroll-snap-align: center;
    overflow: hidden;
  }

  .home-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .homesections {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
