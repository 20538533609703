@import './../../assets/scss/utils.scss';

.link-rounded-container {
  display: inline-flex;
  text-decoration: none;
  color: map-get($flat, dark-blue);
  padding: rem(8px);
  height: rem(44px);
  width: rem(44px);
  flex: 0 0 auto;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: map-get($flat, light-purple);
  position: relative;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: map-get($flat, white);
    background-color: map-get($flat, dark-purple);
  }

  &.with-keyboard:focus {

    &:after {
      border: rem(2px) solid map-get($flat, light-blue);
      border-radius: 100%;
      content: '';
      position: absolute;
      top: rem(-6px);
      right: rem(-6px);
      bottom: rem(-6px);
      left: rem(-6px);
    }
  }

  .svg-icon {
    height: rem(22px);
    width: rem(22px);
    flex: 0 0 auto;
    fill: currentColor;
  }
}
