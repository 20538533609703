@import '../../../../assets/scss/utils.scss';

.intro-step-two-container {
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .content {
    background-color: #f6b8c0;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .label,
  .description-paragraph {
    color: #fff;
  }

  .description {
    width: 100%;
    padding: rem(52px) rem(26px);
    text-align: center;
    background: linear-gradient(180deg, rgba(25, 41, 66, 0) 0%, #192942 31.77%);
    position: relative;
  }

  .description-paragraph {
    max-width: rem(408px);
    width: 100%;
    margin: rem(16px) auto rem(32px) auto;

    @include media-breakpoint-up(md) {
      max-width: rem(520px);
    }
  }

  .rocks-container {
    background: url('../../../../assets/images/parallax.svg') no-repeat center center/cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translateY(-10%);
  }
}
