@import './../../assets/scss/utils.scss';

.footer {
  background-color: map-get($flat, dark-blue);
  padding: rem(80px) rem(16px);
  color: map-get($flat, white);
  // per far andare la navbar sotto il footer
  position: sticky;
  z-index: 100;

  .footer-col-wrapper {
    margin-bottom: rem(56px);
  }

  .container-nav-brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }

    @include media-breakpoint-up(xl) {
      flex-wrap: wrap;
    }
  }

  .svg-wrapper {
    display: inline-block;
    width: rem(124px);
    height: rem(124px);
    flex: 0 0 auto;
    color: map-get($flat, light-purple);
    padding: rem(16px);
    margin-bottom: rem(24px);
    margin-right: rem(100px);
    text-decoration: none;

    @include media-breakpoint-up(xl) {
      margin-bottom: rem(56px);
    }

    .svg-container {
      overflow: visible;
      position: relative;
    }
  }

  .nav-label {
    @extend .paragraph;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: rem(16px);
  }

  .footer-nav {
    flex-direction: column;
    flex-wrap: wrap;
    margin: rem(-8px) 0;
    max-height: rem(108px);

    @include media-breakpoint-up(xl) {
      max-height: none;
    }
    
    .nav-link {
      @extend .text-button-small;
      color: map-get($flat, white);
      transition: color 0.2s ease-in-out;
      padding: rem(8px) 0;
      display: inline-flex;
      align-items: center;

      .icon-lock {
        width: rem(16px);
        height: rem(16px);
        flex: 0 0 auto;
        margin-left: rem(8px);
        color: currentColor;
      }

      &.disabled {
        color: rgba(map-get($flat, white), 0.7);
      }
        
      &:hover {
        color: map-get($flat, light-purple);
      }
    }
  }

  .container-brands {
    display: flex;
    align-items: center;

    .brand-link {
      display: inline-block;
      width: 100%;
    }
  }

  .footer-underline-nav {

    @include media-breakpoint-up(xl) {
      flex-direction: column;
    }

    .nav-link {
      text-decoration: underline;
      color: map-get($flat, white);
      padding-left: 0;
      padding-right: 0;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: map-get($flat, light-purple);
      }
    }

    .nav-item {
      margin-left: rem(16px);

      &:first-child {
        margin-left: 0;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 0;
      }
    }
  }

  .container-socials {
    .nav-item {
      margin-right: rem(65px);

      @include media-breakpoint-up(xl) {
        margin-right: rem(33px);
      }
    }
  }

  .paragraph {
    font-size: rem(14px);
  }
}

.nav-link {
  position: relative;
  outline: none;

  &.with-keyboard:focus {

    &:after {
      border: rem(2px) solid map-get($flat, light-blue);
      border-radius: rem(12px);
      content: '';
      position: absolute;
      top: rem(-6px);
      right: rem(-6px);
      bottom: rem(-6px);
      left: rem(-6px);
    }
  }
}