@import '../../assets/scss/utils.scss';

.cover-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: map-get($flat, light-grey);
  background-image: radial-gradient(at 88% 1%, hsla(196,80%,65%,1) 0, transparent 58%),  radial-gradient(at 34% 65%, hsla(13,80%,64%,1) 0, transparent 59%),  radial-gradient(at 76% 35%, hsla(339,100%,71%,1) 0, transparent 58%),  radial-gradient(at 25% 30%, hsla(50,98%,84%,1) 0, transparent 50%),  radial-gradient(at 49% 48%, hsla(24,77%,69%,1) 0, transparent 50%),  radial-gradient(at 0% 5%, hsla(355,85%,93%,1) 0, transparent 50%),  radial-gradient(at 57% 28%, hsla(288,88%,79%,1) 0, transparent 50%),  radial-gradient(at 13% 86%, hsla(343,49%,61%,1) 0, transparent 50%),  radial-gradient(at 89% 84%, hsla(279,50%,55%,1) 0, transparent 50%),  radial-gradient(at 60% 78%, hsla(335,82%,68%,1) 0, transparent 50%);
  background-color: rgba(240, 240, 240, 1);
  background-blend-mode: overlay;
  background-repeat: no-repeat;

  @include media-breakpoint-up(xl) { 
    background-color: rgba(240, 240, 240, 0.9);
  }
}

.intro-container {
  height: auto;
}

.intro-step-one-container,
.intro-step-two-container,
.intro-step-tree-container,
.intro-step-four-container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.intro-step-one-container {
  text-align: center;
  // opacity: 0;

  .door-mask,
  .content-label {
    opacity: 0;
  }
}

.intro-step-two-container,
.intro-step-tree-container,
.final-step-container {
  visibility: hidden;
}

.intro-step-two-container {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: map-get($flat, dark-blue);
}

.final-step-container {
  .background-cover {
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    background-position: center bottom;
  }

  .background-left {
    background-image: url('../../assets/images/final-intro-1.svg');
    background-position: center top;
  }

  .background-right {
    background-image: url('../../assets/images/final-intro-2.svg');
    background-position: center top;
  }
}
