@import '../../assets/scss/utils.scss';

.about-container {
  padding-top: rem(16px);
  padding-bottom: rem(54px);
  margin-top: $navbar-height;
  min-height: calc(100vh - #{$navbar-height});

  @include media-breakpoint-up(md) {
    padding-top: rem(54px);
  }

  @include media-breakpoint-up(lg) {
    padding-top: rem(64px);
    margin-top: $navbar-height-lg;
    min-height: calc(100vh - #{$navbar-height-lg});
  }

  @include media-breakpoint-up(xl) {
    padding-top: rem(64px);
  }

  .title-extra {
    margin-bottom: rem(16px);
  
    @include media-breakpoint-up(md) {
      margin-bottom: rem(80px);
    }
  
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(40px);
    }
  }

  .container-brands {
    margin-top: rem(16px);
    margin-bottom: rem(16px);
  
    @include media-breakpoint-up(md) {
      margin-bottom: rem(80px);
    }
  
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(40px);
    }
  
    .brand-link {
      margin-right: rem(40px);
  
      &:last-child {
        margin-right: 0;
      }
  
      @include media-breakpoint-up(md) {
        margin-right: rem(20px);
      }
    }
  
    .manitese-brand {
      height: rem(26px);
  
      @include media-breakpoint-up(md) {
        height: rem(32px);
      }
    }
  
    .cariplo-brand {
      height: rem(40px);
  
      @include media-breakpoint-up(md) {
        height: rem(56px);
      }
    }
  }

  .actions-container {
    text-align: center;
    margin-top: rem(24px);

    @include media-breakpoint-up(md) {
      margin-top: rem(80px);
    }

    @include media-breakpoint-up(xl) {
      margin-top: rem(56px);
    }
  }
}

