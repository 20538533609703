@import '../../../../assets/scss/utils.scss';

.planets-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.earth, .mars, .moon {
  position: absolute;
}

.earth {
  z-index: 3;
  width: rem(126px);
}

.mars {
  z-index: 2;
  width: 0;
  left: 8.62%;
  right: 78.81%;
  top: 32.41%;
  bottom: 45.34%;
}

.moon {
  z-index: 1;
  width: 32px;
  left: 57.76%;
  right: 36.37%;
  top: 17.77%;
  bottom: 71.83%;
}
