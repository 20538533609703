@import '../../../assets/scss/utils.scss';

.custom-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include media-breakpoint-up(lg) { 
    flex-direction: row;
  }  
}

.content {
  margin: 0 auto rem(8px) auto;

  @media (min-width: 376px) { 
    margin: 0 auto rem(40px) auto;
  }

  @include media-breakpoint-up(md) { 
    max-width: rem(412px);
  }

  @include media-breakpoint-up(lg) { 
    margin: auto auto auto 0;
  }
}

.description,
.pie-paragraph {
  margin-top: rem(8px);

  @media (min-width: 376px) { 
    margin-top: rem(24px);
  }
}

.pie-paragraph {
  display: inline-block;
}

.btn-container {
  text-align: center;
  flex: 0 0 100%;
}

.mission-counter,
.title,
.description,
.btn-container {
  opacity: 0
}