@import '../../../../assets/scss/utils.scss';

.container {
  margin-bottom: rem(32px);
}

.image {
  margin-bottom: rem(24px);
}

.title {
  width: 100%;
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-width: rem(320px);
  }
}
