@import "~bootstrap/scss/functions";
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';
@import './colors.module.scss';
@import './mixins.module.scss';
@import './fonts.scss';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1400px
);

$navbar-height: 64px;
$navbar-height-lg: 81px;

$container-padding: (
  top: $navbar-height + 8px,
  right: 16px,
  bottom: 16px,
  left: 16px,
);

$container-lg-padding: (
  top: $navbar-height-lg + 32px,
  right: $navbar-height-lg + 62px,
  bottom: 32px,
  left: $navbar-height-lg + 62px,
);

@import './typography.scss';
