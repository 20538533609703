@import '../../assets/scss/utils.scss';

.text-with-ellipse-container {
  position: relative;
  color: map-get($flat, dark-purple);
  display: inline-block;

  .text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: map-get($flat, dark-blue);
  }

  &,
  .text-container {
    width: 100%;
    max-width: rem(168px);
    height: rem(88px);

    @include media-breakpoint-up(md) {
      max-width: rem(216px);
      height: rem(112px);
    }

    @include media-breakpoint-up(xl) {
      max-width: rem(256px);
      height: rem(128px);
    }
  }

  .cover-animated,
  .text-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.mission-end-title-container,
  & .text-container.mission-end-title-container {
    max-width: rem(240px);
    height: rem(135px);

    @include media-breakpoint-up(md) {
      max-width: rem(282px);
      height: rem(168px);
    }

    @include media-breakpoint-up(xl) {
      max-width: rem(325px);
      height: rem(190px);
    }
  }

  &.third-mission-end,
  & .text-container.third-mission-end {
    max-width: rem(300px);
    height: rem(150px);

    @include media-breakpoint-up(md) {
      max-width: rem(588px);
      height: rem(159px);
    }

    @include media-breakpoint-up(lg) {
      max-width: rem(699px);
      height: rem(165px);
    }
  }

  &.subscription-title-container,
  & .text-container.subscription-title-container {
    max-width: rem(300px);
    height: rem(70px);
  }

  &.intro-step-one,
  & .text-container.intro-step-one {
    max-width: rem(160px);
    height: rem(60px);
  }
}
