@import '../../../../assets/scss/utils.scss';

.scroll-indicator-wrapper {
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  display: block;

  .scroll-indicator {
    margin: 0 auto;
    width: 4px;
    height: 100px;
    border-radius: 15px;
    background-color: map-get($flat, light-purple);
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}
