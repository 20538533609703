@import '../../assets/scss/utils.scss';

.audio-btn,
.audio-btn:focus:after {
  border-radius: rem(100px);
}

.audio-btn {
  border: rem(2px) solid map-get($flat, dark-purple);
  color: map-get($flat, dark-purple);
  height: rem(44px);
  padding: rem(8px);
  width: rem(44px);
  transition: transform 0.3s linear;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: scale(1.25)
  }

  &.with-keyboard:focus {
    border-color: map-get($flat, darker-purple);

    &:after {
      border: rem(2px) solid map-get($flat, light-blue);
      content: '';
      padding: rem(4px);
      position: absolute;
      top: rem(-6px);
      right: rem(-6px);
      bottom: rem(-6px);
      left: rem(-6px);
    }
  }
}

.waves {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}

.bar {
  height: 100%;
  max-height: 100%;
  width: rem(2px);
  background: map-get($flat, dark-purple);
  transition: max-height 0.6s linear;

  &:nth-child(1) {
    max-height: rem(6px);
  }

  &:nth-child(2) {
    max-height: rem(12px);
  }

  &:nth-child(3) {
    max-height: rem(16px);
  }

  &.disabled {
    max-height: rem(2px);
  }

  &.infinite {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;

    &:nth-child(1) {
      -webkit-animation-name: volume-low;
      animation-name: volume-low;
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;  
    }
  
    &:nth-child(2) {
      -webkit-animation-name: volume-high;
      animation-name: volume-high;
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
  
    &:nth-child(3) {
      -webkit-animation-name: volume;
      animation-name: volume;
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s;
    }
  }
}

@-webkit-keyframes volume {
  0% {
    height: rem(5px);
  }
  100% {
    height: rem(14px);
  }
}

@keyframes volume {
  0% {
    height: rem(5px);
  }
  100% {
    height: rem(14px);
  }
}

@-webkit-keyframes volume-low {
  0% {
    height: rem(3px);
  }
  100% {
    height: rem(6px);
  }
}

@keyframes volume-low {
  0% {
    height: rem(3px);
  }
  100% {
    height: rem(6px);
  }
}

@-webkit-keyframes volume-high {
  0% {
    height: rem(5px);
  }
  100% {
    height: rem(16px);
  }
}

@keyframes volume-high {
  0% {
    height: rem(5px);
  }
  100% {
    height: rem(16px);
  }
}
