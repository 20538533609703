@import '../../../../assets/scss/utils.scss';

.custom-container {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    justify-content: center;
  }
}

.header,
.description {
  margin-bottom: rem(32px);
}

.description {
  width: 100%;
  max-width: 100%;

  @include media-breakpoint-up(md) { 
    max-width: rem(663px);
  }
}

.header,
.content {
  width: 100%;
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: rem(856px);
    margin: 0 auto;
  }

  @include media-breakpoint-up(xl) {
    max-width: rem(906px);
  }
}

.header {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row-reverse;
    margin-bottom: rem(48px);
  }
}

.content {
  margin-bottom: rem(24px);
}

.mission-counter {
  display: block;
  margin-bottom: rem(24px);

  &.hidden {
    display: none;
  }
}

.title {
  flex: 1;
}

.btn-container {
  text-align: center;
  flex: 0 0 100%;
  justify-self: flex-end;
}

.mission-counter,
.title,
.description,
.list,
.description-end,
.btn-container {
  opacity: 0;
}
