@import '../../../../assets/scss/utils.scss';

.intro-controller {
  .scroller-container {
    position: static;
  
    @include media-breakpoint-up(lg) { 
      position: fixed;
      bottom: rem(map-get($container-lg-padding, bottom));
      right: rem(32px);
    }
  }

  .skip-container {
    position: fixed;
    top: rem(map-get($container-padding, top)); // TODO: fix with navbar height
    right: rem(map-get($container-padding, right));

    @include media-breakpoint-up(lg) { 
      top: unset;
      right: unset;
      bottom: rem(map-get($container-lg-padding, bottom));
      left: rem(32px);
    }
  }
}