@import '../../assets/scss/utils.scss';

.MuiTextField-root  {
  & > label {
    font-family: 'HKGrotesk';
    color: map-get($flat, dark-blue);
    font-weight: 700;
    font-size: rem(14px);
    line-height: rem(16px);
    letter-spacing: unset;
  }
}